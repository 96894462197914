import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { PAYMENT_TYPE_OPTIONS } from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import { UPDATE_VENDOR_PAYMENT } from '../../../../services/graphql/vendors/payments/paymentsMutation';
import type { VendorPayment } from '../../../../types/dropoffs';
import {
  convertToAmericanDateFormat,
  convertToAmericanTimeFormat,
} from '../../../../utils/convertTimestamp';
import Dropdown from '../../common/Inputs/Dropdown';
import InputCardField from '../../common/Inputs/InputCardField';
import Toggle from '../../common/Toggle';

type Props = {
  payment: VendorPayment;
  setValue: (
    key: string,
    value: string | number | Date | null | boolean,
  ) => void;
  onUpdatePayment: () => void;
};

const DropoffPaymentCard = ({ payment, setValue, onUpdatePayment }: Props) => {
  const { t } = useTranslation('common');
  const [updateDropoff] = useMutation(UPDATE_VENDOR_PAYMENT);
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const convertToString = (
    value: string | number | null | Date | boolean,
  ): string | null => {
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    }
    if (value === null) return null;
    return String(value);
  };

  const handleUpdate = (
    key: keyof VendorPayment,
    value: string | number | Date | null | boolean,
  ) => {
    setValue(key, value);
    updateDropoff({
      variables: {
        key,
        value: convertToString(value),
        id: payment.id,
      },
      onCompleted: () => {
        notifySaveSuccess();
        onUpdatePayment();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <div className="box-shadow flex flex-col items-center justify-start gap-[0.2rem] rounded-lg px-6 py-4">
      <div className="flex w-full items-center justify-between gap-2 text-xs text-gray-400">
        <p>{convertToAmericanDateFormat(payment.createdAt.toString())}</p>
        <p>{convertToAmericanTimeFormat(payment.createdAt.toString())}</p>
      </div>
      <div className="flex max-w-[250px] flex-col gap-y-2">
        <InputCardField
          id="amount"
          type="number"
          format="money"
          doNotUseSaveSelfButton
          label={t('vendor-dropoff-card.payment-amount')}
          selectedValue={payment.amount}
          placeholder={payment.amount}
          onSelectChange={(val) => {
            setValue('amount', Number(val));
          }}
          onSave={() => {
            handleUpdate('amount', payment.amount);
          }}
        />
        <div className="flex items-center justify-between gap-2 border-b border-gray-400">
          <div className="truncate text-sm !font-normal text-gray-400">
            {t('vendor-dropoff-card.payment-type')}
          </div>
          <Dropdown
            options={PAYMENT_TYPE_OPTIONS || []}
            selectedValue={payment.payment_type || ''}
            placeholder={t('vendor-dropoff-card.payment-type')}
            onSelectChange={(newValue) => {
              handleUpdate('payment_type', newValue);
            }}
            selectClassname="!font-normal"
          />
        </div>
        {payment.payment_type === 'Checks' && (
          <InputCardField
            id="check"
            type="text"
            doNotUseSaveSelfButton
            label={t('vendor-dropoff-card.check-number')}
            selectedValue={payment.check_number}
            placeholder={payment.check_number}
            onSelectChange={(val) => {
              setValue('check_number', val as string);
            }}
            onSave={() => {
              handleUpdate('check_number', payment.check_number);
            }}
          />
        )}
        {payment.payment_type !== 'Checks' && (
          <InputCardField
            id="transaction_number"
            type="text"
            doNotUseSaveSelfButton
            label={t('vendor-dropoff-card.transaction-number')}
            selectedValue={payment.transaction_number}
            placeholder={payment.transaction_number}
            onSelectChange={(val) => {
              setValue('transaction_number', val as string);
            }}
            onSave={() => {
              handleUpdate('transaction_number', payment.transaction_number);
            }}
          />
        )}
        {payment.payment_type !== 'Loan Credit' && (
          <div className="flex w-full items-center justify-between gap-2 border-b border-gray-400">
            <Toggle
              onSelectChange={(newValue) => {
                if (newValue) {
                  handleUpdate('paid_at', new Date());
                } else {
                  handleUpdate('paid_at', false);
                }
              }}
              selectedValue={!!payment.paid_at}
              placeholder={
                payment.payment_type === 'Checks'
                  ? t('vendor-dropoff-card.check-cashed')
                  : t('vendor-dropoff-card.paid')
              }
              className="w-full"
              placeholderClassName="!text-gray-400"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DropoffPaymentCard;
