import dayjs from 'dayjs';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import calendarIcon from '../../../../assets/icons/calendar.svg';
import { RANGE_DATE_DROPDOWN_OPTIONS } from '../../../../config/constants';
import DropdownWithBorders from './DropdownWithBorders';

type Props = {
  label?: string;
  startDate: number | string | null;
  endDate: number | string | null;
  onSelectStartChange: (value: number | string) => void;
  onSelectEndChange: (value: number | string) => void;
  updateDates: (start: number | string, end: number | string) => void;
  placeholder?: string;
  disabled?: boolean;
  defaultDropdown?: 'today' | 'last_7' | 'last_30' | 'personalized';
  saveAsString?: boolean;
};

const RangeDateField = ({
  startDate,
  endDate,
  onSelectStartChange,
  onSelectEndChange,
  updateDates,
  label,
  placeholder,
  disabled = false,
  defaultDropdown,
  saveAsString = false,
}: Props) => {
  const { t } = useTranslation('common');
  const [dropdownValue, setDropdownValue] = useState<string>(
    defaultDropdown || 'last_7',
  );

  const onDropdownChange = async (val: string) => {
    setDropdownValue(val);
    if (val === 'personalized') return;
    let start = dayjs().startOf('day');
    if (val === 'last_7') {
      start = dayjs().subtract(7, 'days').startOf('day');
    } else if (val === 'last_30') {
      start = dayjs().subtract(30, 'days').startOf('day');
    } else if (val === 'today') {
      start = dayjs().startOf('day');
    }
    if (saveAsString) {
      updateDates(
        start.format('YYYY-MM-DD'),
        dayjs().endOf('day').format('YYYY-MM-DD'),
      );
    } else {
      updateDates(start.valueOf(), dayjs().endOf('day').valueOf());
    }
  };

  return (
    <div className="flex h-full flex-row gap-x-1">
      <DropdownWithBorders
        options={RANGE_DATE_DROPDOWN_OPTIONS.map((o) => ({
          value: o,
          label: t(`global.${o}`),
        }))}
        selectedValue={dropdownValue}
        id="range-dropdown"
        onSelectChange={onDropdownChange}
        selectClassname="h-full"
        fullHeight
      />
      <div className="flex h-[41px] w-auto flex-row items-center rounded-md border border-green px-2.5">
        <div className="flex h-full items-center justify-center">
          <img src={calendarIcon} alt="calendar icon" />
        </div>
        <div className="flex w-[90px] items-center justify-center">
          <DatePicker
            id={label}
            calendarStartDay={1}
            dateFormat={'MM/dd/yyyy'}
            className="w-full cursor-pointer border-none bg-white p-0 text-center  text-xs text-green placeholder:text-[#5A5A5A] focus:ring-0 focus:ring-transparent focus:ring-offset-0"
            selected={startDate ? new Date(startDate) : null}
            onChange={(date: Date) => {
              setDropdownValue('personalized');
              if (saveAsString) {
                onSelectStartChange(
                  dayjs(date).startOf('day').format('YYYY-MM-DD'),
                );
              } else {
                onSelectStartChange(dayjs(date).startOf('day').valueOf());
              }
            }}
            placeholderText={placeholder || ''}
            disabled={disabled}
            autoComplete="off"
            maxDate={endDate ? new Date(endDate) : null}
          />
        </div>
        <span>-</span>
        <div className="flex w-[90px] items-center">
          <DatePicker
            id={label}
            calendarStartDay={1}
            dateFormat={'MM/dd/yyyy'}
            className="w-full cursor-pointer border-none bg-white p-0 text-center text-xs text-green placeholder:text-[#5A5A5A] focus:ring-0 focus:ring-transparent focus:ring-offset-0"
            selected={endDate ? new Date(endDate) : null}
            onChange={(date: any) => {
              setDropdownValue('personalized');
              if (saveAsString) {
                onSelectEndChange(
                  dayjs(date).endOf('day').format('YYYY-MM-DD'),
                );
              } else {
                onSelectEndChange(dayjs(date).endOf('day').valueOf());
              }
            }}
            placeholderText={placeholder || ''}
            disabled={disabled}
            autoComplete="off"
            minDate={startDate ? new Date(startDate) : null}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeDateField;
