import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import LoaderSmall from '../../components/ui/LoaderSmall';
import DropoffReducedCard from '../../components/ui/vendors/dropoffs/DropoffReducedCard';
import DTVendorsLoans from '../../components/ui/vendors/loans/DTVendorLoans';
import StatCard from '../../components/ui/vendors/StatCard';
import DTVendorsTransactions from '../../components/ui/vendors/transactions/TransactionsTable';
import useDropoffList from '../../hooks/useDropoffList';
import { selectedVendor } from '../../recoil/navigation';
import { needRefreshState } from '../../recoil/needRefresh';
import { GET_VENDOR_TRANSACTIONS_STATS } from '../../services/graphql/vendors/vendorsQuery';
import type {
  GetVendorTransactionsStatsResponse,
  VendorTransactionStats,
} from '../../types/vendors';
import { formatNumberMoney } from '../../utils/numberFormatter';
import SingleVendorLayout from './SingleVendorLayout';

const VendorTransactionsView = () => {
  const { t } = useTranslation('common');
  const { vendor } = useParams();
  const vendorInfo = useRecoilValue(selectedVendor);
  const needsRefresh = useRecoilValue(needRefreshState);
  const [metrics, setMetrics] = useState<VendorTransactionStats>({
    balance: 0,
    total_negative: 0,
    total_positive: 0,
  });
  // === QUERIES ===
  const { refetch: refecthStats } =
    useQuery<GetVendorTransactionsStatsResponse>(
      GET_VENDOR_TRANSACTIONS_STATS,
      {
        skip: !vendor,
        variables: { vendorId: vendor },
        onCompleted: (data) => {
          setMetrics(data.getVendorTransactionsStats.data);
        },
      },
    );

  const {
    // fetchMoreDropoffs,
    loading,
    dropoffs,
    // errorTimeline,
    loadingSingleQuery,
    refetchSingleDropoff,
    refetchDropoffs,
  } = useDropoffList({
    vendorId: vendor,
    statusFilter: ['In Review', 'Pending Payments'],
  });

  useEffect(() => {
    if (
      needsRefresh &&
      needsRefresh !== 'new-loan' &&
      needsRefresh !== 'payment-update'
    )
      refetchDropoffs();
    if (needsRefresh) refecthStats();
  }, [needsRefresh]);

  return (
    <>
      <SingleVendorLayout
        breadcrumbs={t('vendor-transactions.title-breadcrumps')}
      >
        <>
          {/* Metrics */}
          <div className="w-full">
            <h2 className="mb-5 text-xl font-semibold">
              {t('vendor-transactions.title')}
            </h2>
            <div className="flex flex-row justify-between gap-x-3">
              <StatCard
                color="text-black"
                stat={formatNumberMoney(metrics.total_positive)}
                subtitle={t('vendor-transactions.graph1')}
                type="medium"
              />
              <StatCard
                color={metrics.balance > 0 ? 'text-[#00870D]' : 'text-red'}
                stat={formatNumberMoney(metrics.balance)}
                subtitle={t('vendor-transactions.graph2')}
                type="medium"
              />
            </div>
          </div>
          {/* Transactions Table */}
          <DTVendorsTransactions vendorId={vendor || ''} />
          {/* Dropoffs List */}
          <div className="w-full pt-10">
            <h2 className="text-xl font-semibold">
              {t('vendor-transactions.pending-payment')}
            </h2>
            <div className="flex w-full flex-col">
              {dropoffs && dropoffs.length === 0 && (
                <div className="flex justify-center">
                  <p className="text-center text-xs italic">
                    {t('vendor-overall.no-timeline-items')}
                  </p>
                </div>
              )}
              {loading && <LoaderSmall />}
              {dropoffs &&
                [...dropoffs].map((dropoff) => (
                  <DropoffReducedCard
                    key={dropoff.id}
                    vendorId={vendorInfo.id}
                    vendorName={vendorInfo.name}
                    dropoff={dropoff}
                    loadingSingleQuery={loadingSingleQuery}
                    refetchSingleDropoff={refetchSingleDropoff}
                  />
                ))}
            </div>
          </div>
          {/* Credit Loans */}
          <div className="w-full">
            <h2 className="mb-5 text-xl font-semibold">
              {t('vendor-transactions.client-loans')}
            </h2>
            <DTVendorsLoans vendorId={vendor} />
          </div>
        </>
      </SingleVendorLayout>
    </>
  );
};

export default VendorTransactionsView;
