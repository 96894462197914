import { gql } from '@apollo/client';

export const GET_VENDOR_DROPOFF = gql`
  query GetVendorDropoffs(
    $vendorId: String!
    $limit: Int
    $offset: Int
    $statusFilter: [String]
  ) {
    getVendorDropoffs(
      vendorId: $vendorId
      limit: $limit
      offset: $offset
      statusFilter: $statusFilter
    ) {
      status
      code
      count
      data {
        id
        vendor_id
        driver_id
        truck_id
        location_id
        transaction_id
        date_dropoff
        vehicle_decal_number
        total_gallons
        total_pounds
        container_volume
        miu_percentage
        ffa
        sulfur
        registered_by
        traceability
        manifest_number
        status
        miu
        net_ikg
        price_per_pound
        measuring_method
        distribution
        total_amount
        location {
          id
          name
        }
        vendor_driver {
          id
          name
        }
        vendor_truck {
          id
          license_plate
        }
        User {
          id
          name
        }
        vendor_payments {
          id
          amount
          payment_type
          transaction_number
          check_number
          paid_at
          createdAt
        }
        Documents {
          id
          name
          type
          date
          url
        }
      }
      error
    }
  }
`;

export const GET_DROPOFFS = gql`
  query GetDropoffs(
    $vendorNameFilter: String
    $statusFilter: [String]
    $vendorIds: [String]
    $locationId: [String]
    $totalGallonsMin: Float
    $totalGallonsMax: Float
    $avgMiuMin: Float
    $avgMiuMax: Float
    $gallonPriceMin: Float
    $gallonPriceMax: Float
    $totalPaidMin: Float
    $totalPaidMax: Float
    $ffaMin: Float
    $ffaMax: Float
    $sulfurMin: Float
    $sulfurMax: Float
    $startDate: String
    $endDate: String
    $limit: Int
    $offset: Int
  ) {
    getDropoffs(
      vendorNameFilter: $vendorNameFilter
      statusFilter: $statusFilter
      vendorIds: $vendorIds
      locationId: $locationId
      totalGallonsMin: $totalGallonsMin
      totalGallonsMax: $totalGallonsMax
      avgMiuMin: $avgMiuMin
      avgMiuMax: $avgMiuMax
      gallonPriceMin: $gallonPriceMin
      gallonPriceMax: $gallonPriceMax
      totalPaidMin: $totalPaidMin
      totalPaidMax: $totalPaidMax
      ffaMin: $ffaMin
      ffaMax: $ffaMax
      sulfurMin: $sulfurMin
      sulfurMax: $sulfurMax
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
    ) {
      status
      code
      count
      data {
        id
        vendor_id
        driver_id
        truck_id
        location_id
        transaction_id
        date_dropoff
        vehicle_decal_number
        total_gallons
        total_pounds
        container_volume
        miu_percentage
        ffa
        sulfur
        registered_by
        traceability
        manifest_number
        status
        miu
        net_ikg
        price_per_pound
        measuring_method
        distribution
        total_amount
        location {
          id
          name
        }
        vendor_driver {
          id
          name
        }
        vendor_truck {
          id
          license_plate
        }
        User {
          id
          name
        }
        vendor_payments {
          id
          amount
          payment_type
          transaction_number
          check_number
          paid_at
          createdAt
        }
        Documents {
          id
          name
          type
          date
          url
        }
        vendor {
          id
          name
        }
      }
      error
    }
  }
`;

export const GET_VENDOR_DROPOFF_STATS = gql`
  query GetVendorDropoffsStats($vendorId: String!) {
    getVendorDropoffsStats(vendorId: $vendorId) {
      status
      code
      data {
        totals {
          total_gallons
          miu_percentage
        }
        last10 {
          total_gallons
          miu_percentage
          date_dropoff
        }
        lastPayments {
          amount
        }
        totalPayments
      }
    }
  }
`;

export const GET_DROPOFF_STATS = gql`
  query GetDropoffsStats(
    $vendorId: String
    $vendorNameFilter: String
    $statusFilter: [String]
    $vendorIds: [String]
    $locationId: [String]
    $totalGallonsMin: Float
    $totalGallonsMax: Float
    $avgMiuMin: Float
    $avgMiuMax: Float
    $gallonPriceMin: Float
    $gallonPriceMax: Float
    $totalPaidMin: Float
    $totalPaidMax: Float
    $ffaMin: Float
    $ffaMax: Float
    $sulfurMin: Float
    $sulfurMax: Float
    $startDate: String
    $endDate: String
  ) {
    getDropoffsStats(
      vendorId: $vendorId
      vendorNameFilter: $vendorNameFilter
      statusFilter: $statusFilter
      vendorIds: $vendorIds
      locationId: $locationId
      totalGallonsMin: $totalGallonsMin
      totalGallonsMax: $totalGallonsMax
      avgMiuMin: $avgMiuMin
      avgMiuMax: $avgMiuMax
      gallonPriceMin: $gallonPriceMin
      gallonPriceMax: $gallonPriceMax
      totalPaidMin: $totalPaidMin
      totalPaidMax: $totalPaidMax
      ffaMin: $ffaMin
      ffaMax: $ffaMax
      sulfurMin: $sulfurMin
      sulfurMax: $sulfurMax
      startDate: $startDate
      endDate: $endDate
    ) {
      status
      code
      data {
        totals {
          total_gallons
          miu_percentage
        }
        last10 {
          total_gallons
          miu_percentage
          date_dropoff
        }
        lastPayments {
          amount
        }
        totalPayments
      }
    }
  }
`;

export const GET_SINGLE_DROPOFF = gql`
  query GetVendorDropoff($id: String!) {
    getVendorDropoff(id: $id) {
      status
      code
      data {
        id
        vendor_id
        driver_id
        truck_id
        location_id
        transaction_id
        date_dropoff
        vehicle_decal_number
        total_gallons
        total_pounds
        container_volume
        miu_percentage
        ffa
        sulfur
        registered_by
        traceability
        manifest_number
        status
        miu
        net_ikg
        price_per_pound
        measuring_method
        distribution
        total_amount
        location {
          id
          name
        }
        vendor_driver {
          id
          name
        }
        vendor_truck {
          id
          license_plate
        }
        User {
          id
          name
        }
        vendor_payments {
          id
          amount
          payment_type
          transaction_number
          check_number
          paid_at
          createdAt
        }
        Documents {
          id
          name
          type
          date
          url
        }
        vendor {
          id
          name
        }
      }
      error
    }
  }
`;

export const GET_DROPOFF_OPTIONS = gql`
  query GetDropoffOptions($vendorId: String) {
    getDropoffOptions(vendorId: $vendorId) {
      status
      code
      data {
        userOptions {
          label
          value
        }
        driverOptions {
          label
          value
          vendor_id
        }
        locationOptions {
          label
          value
          measuring_method
        }
        vendorOptions {
          label
          value
        }
        truckOptions {
          label
          value
          license_plate
          tk
          capacity
        }
      }
      error
    }
  }
`;
