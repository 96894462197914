import dayjs from 'dayjs';
import { selector } from 'recoil';

import type { TDropoffsFilters } from './dropoffFilters';
import { dropoffsFiltersState } from './dropoffFilters';

export const dropoffsCleanFiltersState = selector({
  key: 'dropoffsCleanTheFilters',
  get: ({ get }) => {
    const currentFilters = get(dropoffsFiltersState);

    // Limpia los filtros y devuelve una nueva copia con todo en null
    const cleanedFilters: TDropoffsFilters = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      if (key === 'startDate')
        cleanedFilters[key] = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
      else if (key === 'endDate')
        cleanedFilters[key] = dayjs().format('YYYY-MM-DD');
      else cleanedFilters[key] = null;
    });

    return cleanedFilters;
  },
});
