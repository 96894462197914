import { useMutation } from '@apollo/client';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';

import capacityIcon from '../../../assets/icons/capacity.svg';
import licenseIcon from '../../../assets/icons/licensing.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import vendorIcon from '../../../assets/icons/vendor.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { vendorTruckToEdit } from '../../../recoil/dataToEdit';
import { confirmationModalState } from '../../../recoil/modalDelete';
import { needRefreshState } from '../../../recoil/needRefresh';
import {
  CREATE_VENDOR_TRUCK,
  DELETE_VENDOR_TRUCK,
  UPDATE_VENDOR_TRUCK,
} from '../../../services/graphql/vendors/vendorsMutation';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import InputField from '../../ui/common/Inputs/InputField';
import InputNumber from '../../ui/common/Inputs/InputNumber';

type Inputs = {
  license_plate: string;
  tk: string;
  capacity: number;
};

const NewVendorTruck = () => {
  const { t } = useTranslation('common');
  const [truck, setVendorTruck] = useRecoilState(vendorTruckToEdit);
  const { vendor } = useParams();
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const notifyDeleteVendoTruckSuccess = () =>
    toast.success(t('modalNewVendorTruck.deleteSuccess'));

  // Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      license_plate: truck?.license_plate,
      tk: truck?.tk,
      capacity: truck?.capacity,
    },
  });

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const setConfirmModalState = useSetRecoilState(confirmationModalState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const handleFormReset = () => {
    reset();
    setVendorTruck({
      tk: '',
      id: '',
      capacity: undefined,
      license_plate: '',
    });
  };

  // Query
  const [deleteVendorTruck] = useMutation(DELETE_VENDOR_TRUCK);

  const [createVendorTruck, { loading: createVendorTruckLoading }] =
    useMutation<any>(CREATE_VENDOR_TRUCK, {
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifySaveError();
      },
    });
  const [updateVendorTruck, { loading: updateVendorTruckLoading }] =
    useMutation<any>(UPDATE_VENDOR_TRUCK, {
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifySaveError();
      },
    });

  // On Submit
  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    if (createVendorTruckLoading || updateVendorTruckLoading) return;
    if (truck?.id) {
      updateVendorTruck({
        variables: {
          ...data,
          capacity: Number(data.capacity),
          id: truck.id,
        },
      });
    } else {
      createVendorTruck({
        variables: {
          ...data,
          capacity: Number(data.capacity),
          vendor_id: vendor,
        },
      });
    }
  };

  const handleDeleteModal = (event: any) => {
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        deleteVendorTruck({
          variables: { id: truck?.id },
          onCompleted: () => {
            closeOrder();
            handleFormReset();
            notifyDeleteVendoTruckSuccess();
          },
        });
      },
      onCancel: () => {},
      message: t('global.are-you-sure-delete'),
    });
    event.preventDefault();
    return false;
  };

  return (
    <>
      <Breadcrumb
        title={`${t('vendors.title-h1')} > ${t(
          'modalNewVendorTruck.newTruckBreadcrumb',
        )}`}
        image={vendorIcon}
        id="VendorTruckModal"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewVendorTruck.newTruckTitle')}
      </h1>
      <form className="my-6">
        {/* license plate Input */}
        <InputField
          label={t('modalNewVendorTruck.license')}
          selectedValue={watch('license_plate')}
          doNotUseSaveSelfButton
          type="text"
          icon={licenseIcon}
          errors={errors.license_plate}
          register={register}
          registerName="license_plate"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        {/* tk Input */}
        <InputField
          label={t('modalNewVendorTruck.tk')}
          selectedValue={watch('tk')}
          doNotUseSaveSelfButton
          type="text"
          icon={licenseIcon}
          errors={errors.tk}
          register={register}
          registerName="tk"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        {/* Capacity IKG Input */}
        <InputNumber
          icon={capacityIcon}
          control={control}
          label={t('modalNewVendorTruck.capacity')}
          suffix={` Gal`}
          defaultValue=""
          registerName={'capacity'}
          errors={errors.capacity}
          validations={{
            required: t('global.required'),
          }}
          decimalCount={0}
          labelClassname="!font-[600]"
        />

        {/* Save Button */}
        <div className="mt-2 flex justify-end">
          <Button
            text={t('modalNewVendorTruck.save')}
            variant="green"
            icon={saveBigIcon}
            disabled={createVendorTruckLoading || updateVendorTruckLoading}
            loading={createVendorTruckLoading || updateVendorTruckLoading}
            onClick={handleSubmit(onSubmit)}
            type="button"
            id="save-new-vendor"
          />
        </div>
        {truck && (
          <button
            className="flex w-full items-center justify-center"
            onClick={handleDeleteModal}
          >
            <div className=" flex cursor-pointer items-center justify-center text-center text-xs font-bold text-red underline">
              {t('modalNewVendorTruck.delete')}
            </div>
          </button>
        )}
      </form>
    </>
  );
};

export default NewVendorTruck;
