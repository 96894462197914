/* eslint-disable unused-imports/no-unused-vars */
import 'react-datepicker/dist/react-datepicker.css';

import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import newWithDrawIcon from '../../../assets/icons/newWithDrawIcon.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { needRefreshState } from '../../../recoil/needRefresh';
import { CREATE_VENDOR_LOAN } from '../../../services/graphql/vendors/loans/vendorLoansMutations';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import InputNumber from '../../ui/common/Inputs/InputNumber';

type Inputs = {
  total_amount: number;
};

const NewVendorLoan = () => {
  const { t } = useTranslation('common');
  const { vendor } = useParams();
  const { notifySaveError, notifySaveSuccess } = useNotification();

  // Form
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const { handleCloseAllModals } = useModal();
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const refreshOrder = () => {
    setNeedRefresh('new-loan');
  };
  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const [createVendorLoan, { loading }] = useMutation<any>(CREATE_VENDOR_LOAN, {
    onCompleted: () => {
      notifySaveSuccess();
      closeOrder();
      reset();
    },
    onError: () => {
      notifySaveError();
    },
  });

  const onSubmit = (data: Inputs) => {
    createVendorLoan({
      variables: {
        ...data,
        vendorId: vendor,
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <>
      <Breadcrumb
        title={t('modalNewVendorLoan.newReceiptBreadcrumbTitle')}
        secondTitle={t('modalNewVendorLoan.newReceiptBreadcrumb')}
        image={newWithDrawIcon}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="my-6 text-xl font-[600]">
          {t('modalNewVendorLoan.newReceiptTitle')}
        </p>

        {/* <InputField
          label={t('modalNewVendorLoan.amount')}
          type="number"
          onSelectChange={handleAmountChange}
          selectedValue={amount}
          placeholder={t('modalNewVendorLoan.amount')}
          doNotUseSaveSelfButton={true}
          moneyFormat
        /> */}

        <InputNumber
          control={control}
          label={t('modalNewVendorLoan.amount')}
          prefix="$"
          registerName="total_amount"
          defaultValue=""
          errors={errors.total_amount}
          validations={{
            required: t('global.required'),
          }}
        />

        <div className="mt-6 flex justify-end">
          <Button
            text={t('modalNewVendorLoan.save')}
            variant="green"
            icon={saveBigIcon}
            disabled={loading}
            loading={loading}
            type="submit"
            id="save-new-receipt"
          />
        </div>
      </form>
    </>
  );
};

export default NewVendorLoan;
