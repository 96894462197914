import { useLazyQuery, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import type { TDropoffsFilters } from '@/recoil/dropoffFilters';
import type {
  Dropoff,
  DropoffQuery,
  SingleDropoffQuery,
  VendorDropoffQuery,
} from '@/types/dropoffs';

import {
  DROPOFFS_MAX_RESULTS,
  MAX_NUMBER_OF_TIMELINES,
} from '../config/constants';
import {
  GET_DROPOFFS,
  GET_SINGLE_DROPOFF,
  GET_VENDOR_DROPOFF,
} from '../services/graphql/vendors/dropoffs/dropoffsQuery';

const useDropoffList = ({
  vendorId,
  ...filters
}: { vendorId?: string } & TDropoffsFilters) => {
  const [dropoffs, setDropoffs] = useState<Dropoff[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const {
    // data,
    fetchMore,
    loading,
    error: errorTimeline,
    refetch,
    updateQuery: updateQueryTimeline,
  } = useQuery<DropoffQuery | VendorDropoffQuery>(
    vendorId ? GET_VENDOR_DROPOFF : GET_DROPOFFS,
    {
      variables: {
        vendorId,
        offset: 0,
        limit: DROPOFFS_MAX_RESULTS,
        ...filters,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (res: DropoffQuery | VendorDropoffQuery) => {
        setHasNextPage(true);
        if (vendorId)
          setDropoffs((res as VendorDropoffQuery).getVendorDropoffs.data || []);
        else setDropoffs((res as DropoffQuery).getDropoffs.data || []);
      },
    },
  );

  const [fetchSingleQuery, { loading: loadingSingleQuery }] =
    useLazyQuery<SingleDropoffQuery>(GET_SINGLE_DROPOFF, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

  function calculateHasNextPage(total: number, currentTotal: number) {
    return currentTotal < total;
  }

  function refetchDropoffs(type?: string | undefined | null) {
    setHasNextPage(true);
    setDropoffs([]);

    let filter: string[] | undefined;

    if (type !== null && type !== undefined) {
      if (type !== 'All') {
        filter = [type];
      }
    }

    refetch({
      vendorId,
      offset: 0,
      limit: DROPOFFS_MAX_RESULTS,
      type: filter,
      ...filters,
    });
  }

  const fetchMoreDropoffs = useCallback(() => {
    if (dropoffs.length === MAX_NUMBER_OF_TIMELINES) return;

    fetchMore({
      variables: {
        vendorId,
        offset: dropoffs.length,
        limit: DROPOFFS_MAX_RESULTS,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newData = vendorId
          ? (fetchMoreResult as VendorDropoffQuery).getVendorDropoffs.data || []
          : (fetchMoreResult as DropoffQuery).getDropoffs.data || [];
        const count = vendorId
          ? (fetchMoreResult as VendorDropoffQuery).getVendorDropoffs.count || 0
          : (fetchMoreResult as DropoffQuery).getDropoffs.count || 0;
        const newDropoffs = [...dropoffs, ...newData].reduce(
          (p: Dropoff[], c: Dropoff) => {
            // if the next object's id is not found in the output array
            // push the object into the output array
            if (
              !p.some((el) => {
                return el.id === c.id;
              })
            )
              p.push(c);
            return p;
          },
          [],
        );
        setDropoffs(newDropoffs);

        // Verificar si hay más páginas después de la página actual
        setHasNextPage(calculateHasNextPage(count, newDropoffs.length));

        const prevValue = vendorId
          ? (prev as VendorDropoffQuery).getVendorDropoffs || []
          : (prev as DropoffQuery).getDropoffs || [];
        return vendorId
          ? {
              getVendorDropoffs: {
                ...prevValue,
                data: [...(prevValue.data || []), ...newData],
              },
            }
          : {
              getDropoffs: {
                ...prevValue,
                data: [...(prevValue.data || []), ...newData],
              },
            };
      },
    });
  }, [fetchMore, dropoffs, vendorId]);

  const refetchSingleDropoff = (id: string) => {
    fetchSingleQuery({
      variables: { id },
      onCompleted: (res) => {
        const i = dropoffs.findIndex((d) => d.id === id);
        if (i > -1 && res.getVendorDropoff.data) {
          const newDropoffs = [...dropoffs];
          newDropoffs[i] = { ...res.getVendorDropoff.data };
          setDropoffs([...newDropoffs]);
        }
      },
    });
  };

  return {
    fetchMoreDropoffs,
    loading,
    dropoffs,
    refetchDropoffs,
    updateQueryTimeline,
    errorTimeline,
    hasNextPage,
    loadingSingleQuery,
    refetchSingleDropoff,
  };
};

export default useDropoffList;
