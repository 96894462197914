import { useQuery } from '@apollo/client';
import type { PaginationState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import arrowDownIcon from '../../../../assets/icons/arrow-down.svg';
import beforeIcon from '../../../../assets/icons/before.svg';
import beforemaxIcon from '../../../../assets/icons/beforemax.svg';
import chargeIcon from '../../../../assets/icons/charge-arrow.svg';
import nextIcon from '../../../../assets/icons/next.svg';
import nextmaxIcon from '../../../../assets/icons/nextmax.svg';
import paymentIcon from '../../../../assets/icons/payment-arrow.svg';
import { VENDORS_TRANSACTIONS_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { GET_VENDOR_TRANSACTIONS } from '../../../../services/graphql/vendors/vendorsQuery';
import type {
  GetVendorTransactionsResponse,
  VendorTransaction,
} from '../../../../types/vendors';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import { formatNumberMoney } from '../../../../utils/numberFormatter';
import LoaderSmall from '../../LoaderSmall';

type PaginationProps = {
  pageIndex: number;
  total: number;
  setPageIndex: (val: number) => void;
  loading: boolean;
  pageSize: number;
  setPageSize: (val: number) => void;
};

const Pagination = ({
  pageIndex,
  total,
  setPageIndex,
  loading,
  pageSize,
  setPageSize,
}: PaginationProps) => {
  const getCanPreviousPage = () => {
    return pageIndex > 0;
  };

  const getPageCount = () => {
    return Math.ceil(total / pageSize);
  };

  const getCanNextPage = () => {
    return getPageCount() > pageIndex + 1;
  };

  return (
    <div className="">
      <div className="flex items-center gap-2">
        <span className="text-[14px] font-medium">Page</span>
        <div className="flex h-[29px] items-center justify-center rounded-[5px] border border-greenlight">
          <button
            onClick={() => setPageIndex(0)}
            disabled={!getCanPreviousPage()}
            className={`${!getCanPreviousPage() ? 'opacity-30' : ''}`}
          >
            <img src={beforemaxIcon} alt="before icon" />
          </button>
          <button
            onClick={() => setPageIndex(pageIndex - 1)}
            disabled={!getCanPreviousPage()}
            className={`${!getCanPreviousPage() ? 'opacity-30' : ''}`}
          >
            <img src={beforeIcon} alt="before icon" />
          </button>
          <div className="flex items-center gap-1">
            <div className="dropdown-container relative inline-flex">
              <select
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  setPageIndex(page);
                }}
                style={{
                  backgroundImage: `url(${arrowDownIcon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 2px center',
                  backgroundSize: '16px 16px',
                  cursor: 'pointer',
                }}
                className="inline-block h-[24px] w-[40px] border-none pr-1 text-center text-[14px] font-normal focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
              >
                {Array.from({ length: getPageCount() }, (_, index) => (
                  <option
                    className="text-center"
                    key={index + 1}
                    value={index + 1}
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            onClick={() => setPageIndex(pageIndex + 1)}
            disabled={!getCanNextPage()}
            className={`${!getCanNextPage() ? 'opacity-30' : ''}`}
          >
            <img src={nextIcon} alt="next icon" />
          </button>
          <button
            onClick={() => setPageIndex(getPageCount() - 1)}
            disabled={!getCanNextPage()}
            className={`${!getCanNextPage() ? 'opacity-30' : ''}`}
          >
            <img src={nextmaxIcon} alt="next icon" />
          </button>
        </div>

        <span className="text-[14px] font-medium">Show</span>
        <select
          className="inline-block h-[28px] w-[63px] rounded-[5px] border border-greenlight pl-2 pr-4 text-center text-[14px] font-normal focus:outline-none focus:ring-0 focus:ring-offset-0"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          style={{
            backgroundImage: `url(${arrowDownIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 10px center',
            backgroundSize: '16px 16px',
            cursor: 'pointer',
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((_pageSize) => (
            <option key={_pageSize} value={_pageSize}>
              {_pageSize}
            </option>
          ))}
        </select>
        {loading && (
          <div>
            <LoaderSmall />
          </div>
        )}
      </div>
    </div>
  );
};
type TransactionElementProps = {
  type: string;
  transactionType: 'payment' | 'charge';
  updatedAt: Date;
  amount: number;
};

const TransactionElement = ({
  type,
  updatedAt,
  amount,
  transactionType,
}: TransactionElementProps) => {
  return (
    <div className="flex w-full flex-row justify-between text-sm">
      <div className="flex flex-row gap-x-2">
        {transactionType === 'payment' ? (
          <div className="flex flex-row gap-x-2">
            <img className="h-[24px]" src={paymentIcon} alt="trash icon" />
            <span className="text-[#00870D]">Payment</span>
          </div>
        ) : (
          <div className="flex flex-row gap-x-2">
            <img className="h-[24px]" src={chargeIcon} alt="trash icon" />
            <span className="text-reyesgold">Charge</span>
          </div>
        )}
        <span>·</span>
        <span className="text-gray-400">
          {convertToAmericanDateFormat(updatedAt.toString())}
        </span>
      </div>
      <div className="flex flex-col items-end gap-y-1 text-left">
        <span className="text-md font-bold">
          {/* {transactionType === 'payment' ? '+' : '-'} */}
          {formatNumberMoney(amount)}
        </span>
        <div className="flex flex-row gap-x-1 text-sm text-gray-400">
          <span className="">
            {/* {transactionType === 'payment' ? '+' : '-'} */}
            {formatNumberMoney(amount)}
          </span>
          <span>·</span>
          <span>{type}</span>
        </div>
      </div>
    </div>
  );
};

type DTVendorsTransactionsProps = {
  vendorId?: string;
};

const DTVendorsTransactions = ({ vendorId }: DTVendorsTransactionsProps) => {
  const { t } = useTranslation('common');
  const needsRefresh = useRecoilValue(needRefreshState);

  // const [filters, setFilters] = useRecoilState(vendorsFiltersState);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: VENDORS_TRANSACTIONS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<VendorTransaction[]>([]);
  const [total, setTotal] = useState<number>(0);

  const { refetch, loading } = useQuery<GetVendorTransactionsResponse>(
    GET_VENDOR_TRANSACTIONS,
    {
      skip: !vendorId,
      variables: {
        limit: pageSize,
        offset: pageIndex * pageSize,
        vendorId,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        setTotal(res.getVendorTransactions.count);
        setData(res.getVendorTransactions.data);
      },
    },
  );

  useEffect(() => {
    if (needsRefresh)
      refetch({
        limit: pageSize,
        offset: pageIndex * pageSize,
        vendorId,
      });
  }, [needsRefresh]);

  return (
    <>
      <div className="pt-10">
        <h2 className="text-xl font-semibold">
          {t('vendor-transactions.transactions')}
        </h2>
        <div className="flex flex-col divide-y divide-solid divide-gray-300">
          {data.map((tr) => (
            <div key={tr.id + Math.random} className="px-0.5 pb-5 pt-2">
              <TransactionElement
                amount={tr.amount}
                transactionType={
                  tr.type === 'Loan Credit' || tr.type === 'Dropoff'
                    ? 'payment'
                    : 'charge'
                }
                type={tr.type}
                updatedAt={tr.updatedAt || tr.createdAt}
              />
            </div>
          ))}
        </div>
        <Pagination
          pageIndex={pageIndex}
          total={total}
          setPageIndex={(val: number) =>
            setPagination({ pageIndex: val, pageSize })
          }
          loading={loading}
          pageSize={pageSize}
          setPageSize={(val: number) =>
            setPagination({ pageIndex, pageSize: val })
          }
        />
      </div>
    </>
  );
};

export default DTVendorsTransactions;
